.contHMarket {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-left: 3%;
    padding-right: 3%;
    padding-top: 20px;
    padding-bottom: 10px;

    /* background-color: hotpink; */
}
.hMarket {
    font-size: 26px;
    font-weight: bold;
    margin: 0;
}
.imgUser {
    width: 30px;
    height: 30px;
    padding: 5px;
    margin: 0px;
    border-radius: 50%;
    background-color: rgb(207, 207, 207);
}
.threeBtn {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-left: 5%;
    padding-right: 5%;
    /* background-color: indianred; */
}
.textBtn {
    margin: 0;
    font-size: 14px;
    padding: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 25px;
}
.textBtnFocus {
    margin: 0;
    font-size: 14px;
    background-color: rgb(204, 227, 253);
    padding: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 25px;
    color: rgb(1, 68, 145)
}
.underHead {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-left: 3%;
    padding-right: 3%;
    padding-top: 15px;
    padding-bottom: 5px;
    /* background-color: blueviolet; */
}
.textUnderHome {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: rgb(1, 68, 145);
    margin: 0;
    font-size: 15px;
    
}