.contCreateAd {

}
.headerCreateAd {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;
    padding-left: 3%;
    padding-right: 5%;
    /* padding-bottom: 3%; */
    border-bottom: 1px solid rgb(194, 194, 194)
}
.linkCreateAd{
    width: 50px;
    color: rgb(1, 68, 145);
    text-decoration: underline;
}
.contNameAd {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding-top: 5px;
    padding-left: 3%;
    padding-right: 3%;
}
.imgProfileAd {
    width: 30px;
    height: 30px;
    margin-right: 15px;
    padding: 7.5px;
    border-radius: 50%;
    background-color: rgb(218, 218, 218);
}
.textNameAd {
    font-size: 18px;
    font-weight: 600;
}