.cardEach {
    height: auto;
}

.contentAd {
    display: flex;
    flex-direction: column;
    justify-content:space-between;
    padding-top: 5px;
    padding-left: 5%;
    padding-bottom: 10px;
    /* height: 130px;
    padding: 5%; */
    margin: 0;
    /* padding-bottom: 10px; */
    /* background-color: chocolate; */
    /* background-color: blue; */
}
.contentAd h3 {
    font-size: 18px;
    margin: 0;
    color:rgb(0, 0, 0) ;
    font-weight: normal;
}
.contentAd p {
    color:#747474;
    font-size: 14px;
    margin: 0px;
} 
.containerDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

}
.imageCont {
    /* aspect-ratio: 16/9; */
    width: 100%;
    padding-top: 100%;
    position: relative;
    /* height: 41px; */
    /* border-radius: 5px; */
}
.imageCont img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; 
}
.divHidden {
    /* min-width: 100px;
    max-width: 470px; */
    width: 95%;
    overflow: hidden;
    /* white-space: nowrap; */
    text-overflow: ellipsis;
    /* padding-right: 5px; */
}
.divHidden p {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    /* Дополнительные свойства для кросс-браузерной поддержки */
    display: -moz-box;
    -moz-box-orient: vertical;
    -moz-line-clamp: 2;
}
.divHiddenProperty {
    /* min-width: 100px;
    max-width: 470px; */
      /* white-space: nowrap; */
  /* overflow: hidden; */
    width: 95%;
    overflow: hidden;
    /* white-space: nowrap; */
    text-overflow: ellipsis;
    /* padding-right: 5px; */
}
.divHiddenProperty p {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    /* Дополнительные свойства для кросс-браузерной поддержки */
    display: -moz-box;
    -moz-box-orient: vertical;
    -moz-line-clamp: 1;
}
.timeAgo {
    position: absolute;
    bottom: 155px;
    right: 5px;
    /* width: 100px; */
    /* height: 100px; */
    background-color:rgba(255, 255, 255, 0.7);
    padding-left: 7px;
    padding-right: 7px;
    border-radius: 3px;
    color: black;
}

@media screen and (min-width: 200px) and (max-width: 500px) {
    .card {
        height: 30vh;
        min-height: 290px;
        overflow: hidden;
    }
    .containerDiv {
        height: 145px;
      }

    .contentAd h3 {
        font-size: 16px; 
        
    }
    .contentAd p {
        color:#666666;
        font-size: 14px;
        margin: 0px;
    } 

    .divHidden {
        /* min-width: 100px;
        max-width: 470px; */
        width: 95%;
        overflow: hidden;
        /* white-space: nowrap; */
        text-overflow: ellipsis;
        /* padding-right: 5px; */
    }
    .divHidden p {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        /* Дополнительные свойства для кросс-браузерной поддержки */
        display: -moz-box;
        -moz-box-orient: vertical;
        -moz-line-clamp: 2;
        font-size: 12px;
    }
    .divHiddenProperty {
        /* min-width: 100px;
        max-width: 470px; */
        width: 95%;
        overflow: hidden;
        /* white-space: nowrap; */
        text-overflow: ellipsis;
        /* padding-right: 5px; */
    }
    .divHiddenProperty p {
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        /* Дополнительные свойства для кросс-браузерной поддержки */
        display: -moz-box;
        -moz-box-orient: vertical;
        -moz-line-clamp: 1;
        font-size: 12px;
    }
    

}