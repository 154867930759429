
.contAllAd {
    width: 100%;
    box-sizing: border-box;
    opacity: 1;
    /* padding: 3%; */
    padding-bottom: 20px;
    /* padding-left: 7%;
    padding-right: 7%; */
    padding-top: 0;
    z-index: 1;
}
.contAllAdFilter {
    width: 100%;
    box-sizing: border-box;
    opacity: 1;
    padding-bottom: 3%;
    padding-top: 0;
    z-index: 1;
}
.contAllSortAd {
    width: 100%;
    box-sizing: border-box;
    opacity: 1;
    padding-top: 0;
    z-index: 1;
}
.cover {
    position: fixed;
    width: 100%;
    height: 150%;
    top:-50%;
    background-color: rgba(116, 116, 116, 0.9); ;
} 
.contAllAdHidden {
    z-index: 0;
    pointer-events: none;
    padding: 3%;
    height: 50vh;
    overflow: hidden;

}
.linkAllAd {
    text-decoration: none;
}
.shownAllAdd {
    position: absolute;
    background-color: white;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 2px;
    padding-bottom: 2px;
    border-radius: 3px;
    top: 10px;
    left: 10px;
    z-index: 9;
    opacity: 0.7;
}
.shownAllAdd p {
    font-size: 10px;
    margin: 0;
}
.cards {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(225px, 1fr));
    grid-gap: 5px;
    /* padding-bottom: 20px; */
    /* padding-right: 5%;
    padding-left: 5%; */
    justify-content: center;
    /* style={{gridTemplateColumns:'repeat(auto-fill, minmax(170px, 0.5fr))'}} */
    
}
.sellInfo {
    position: absolute;
    bottom: 20%;
    left: 10px;
    color: white;
    font-size: 14px;
    background-color: rgba(0, 0, 0, 0.8);
    border-radius: 3px;
    padding: 1px;
    padding-left: 3px;
    padding-right: 3px;
    padding-bottom: 2px;
    z-index: 1;
    margin: 0px;
}
.formBtnRed {
    margin-top: 10px;
    width: 100%;
    height: 40px;
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.2);
    border: none;
    border-radius: 15px;
    background-color: white;
}
.modalCont {
    width: 80%;
    height: auto;
    background-color: white;
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.2);
    position: fixed;
    top:5%;
    border-radius: 15px;
    margin-left: 7%;
    padding: 3%;
    z-index: 999;
}
.modalContText {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: auto;
    width: 100%;
}
.modalContHead {
    display: flex;
    justify-content: space-between;
}
.eachImgCont {
    text-align: right;
    overflow:auto;
    display: flex;
    width: 50%;
    height: auto;
    padding: 5px;
    position: relative;
}
.eachImg{
    display: block;
    width: 85%;
    height: auto;
    margin: 5px;
    border-radius: 10px;
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.2);
}
.imgClose {
    position: absolute;
    width: 35px;
    height: 35px;
    top: 3%;
    right: 2%;
    cursor: pointer;
    z-index: 2;
}

.btnRed {
    width: 100%;
    height: 30px;
    box-shadow: 0 0 3px 1.5px rgba(0, 0, 0, 0.2);
    border: none;
    background-color: white;
    border-radius: 5px;
    margin-top: 10px;
    color: #116466 ;
    cursor: pointer;
    font-size: 14px;
}
.btnRed:hover{
    opacity: 0.7;
}
.imgHidden {
    width: 54px;
    height: 54px;
    position: fixed;
    bottom:22px;
    left: 15px;
    z-index: 15;
    opacity: 0.8;
}
.imgHidden:hover {
    opacity: 0.7;
}

@media screen and (min-width: 501px) and (max-width: 835px) {
.cards {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
}
}
@media screen and (min-width: 410px) and (max-width: 500px) {
    .cards {
        grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
        
    }

}
@media screen and (min-width: 200px) and (max-width: 409px) {
    .cards {
        grid-template-columns: repeat(auto-fill, minmax(149px, 1fr));
        
    }

}