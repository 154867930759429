.contHeadSaveProfiles {
    position: relative;
    text-align: center;
    /* background-color: cyan; */
    border-bottom: 0.5px solid rgb(194, 194, 194)
}
.saveProfileBackImg {
    position: absolute;
    left: 3%;
    width: 25px;
    height: 25px;
}
.allMyAdsSave {
    padding-left: 3%;
    padding-right: 3%;
    padding-bottom: 10px;
    
}
.eachmyAdSave {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 5px;
    padding-bottom: 5px;
    border-bottom: 0.5px solid rgb(194, 194, 194)
}
.imageProfilePageSave {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 3px solid white;
    background-color: grey;
    /* margin-bottom: 10px; */
    margin-right: 20px;
    color: white;
    font-size: 23px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.contTextMyAdsSave {
    /* height: 60px; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.text1MyAdSave {
    margin: 0;
    margin-bottom: 5px;
    color: black;
}
.text3MyAdSave {
    margin: 0;
    font-size: 14px;
    color: #1877F2;
}
.imgMyAdsRightSave {
    position: absolute;
    right: 0px;
    width: 20px;
    height: 20px;
    right: 5px;
}





