.moderHead {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: #747474 0.5px solid;
    height: auto;

}
.moderInpButtonCont{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
}
.moderContInput {
    width: 80%;
    display: flex;
    margin-bottom: 20px;
}
.moderContInput:hover {
    opacity: 0.7;
}
.inputModer {
    width: 80%;
    height: 40px;
    border: #116466 solid 1px;
    border-radius: 5px;
    padding-left: 10px;
    box-sizing: border-box;
    font-size: 14px;
}
.inputModer:focus {
    outline: none;
}
.moderBtn {
    background-color: #116466;
    border: #116466 solid 0.5px;
    /* border-left: none; */
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
    width: 20%;
    height: 40px;
    color: white ;
    font-size: 14px;
    cursor: pointer;
    margin-left: -3.5px;
}
.moderLink {
    width: 20%;
    text-decoration: none;
}
.moderAllBtn {
    display: flex;
    flex-direction: row;
    justify-content: left;
    width: 100%;
}
.moderHButton {
    width: 100%;
    max-width: 350px;
    height: 40px;
    border-radius: 5px;
    border: #116466 1px solid;
    background-color: white;
    color: #116466;
    font-size: 14px;
    cursor: pointer;
}
.moderHButton:hover {
    opacity: 0.7;
}
.moderHButtonVis {
    width: 100%;
    max-width: 350px;
    height: 40px;
    border-radius: 5px;
    border: #116466 1px solid;
    background-color: #116466;
    color: white;
    font-size: 14px;
    cursor: pointer;
}
.moderHButtonVis:hover {
    opacity: 0.7;
} 

@media screen and (min-width: 600px) and (max-width: 900px) {
    .moderContInput {
        width: 80%;
        display: flex;
        margin-bottom: 20px;
    }
    .inputModer {
        width: 75%;
        height: 38px;
        border: #116466 solid 2px;
        border-radius: 5px;
        padding-left: 10px;
        box-sizing: border-box;
        font-size: 12px;
    }
    .moderBtn {
        background-color:#116466;
        border: #116466 solid 1px;
        border-bottom-right-radius: 5px;
        border-top-right-radius: 5px;
        width: 25%;
        height: 38px;
        color: white;
        font-size: 14px;
        cursor: pointer;
        margin-left: -3.5px;
    }
    .moderHButton {
        width: 100%;
        max-width: 3150px;
        box-sizing: border-box;
        height: 38px;
        border-radius: 5px;
        border: none;
        border:#116466 1px solid ;
        background-color: white;
        color: #116466;
        font-size: 14px;
        cursor: pointer;
    }
    .moderHButtonVis {
        width: 100%;
        max-width: 3150px;
        box-sizing: border-box;
        height: 38px;
        border-radius: 5px;
        border: none;
        border:#116466 1px solid ;
        background-color: #116466;
        color: white;
        font-size: 14px;
        cursor: pointer;
    }
}
@media screen and (min-width: 200px) and (max-width: 599px) {
    .moderContInput {
        width: 100%;
        display: flex;
        margin: auto;
        margin-bottom: 20px;
    }
    .inputModer {
        width: 75%;
        height: 35px;
        border: #116466 solid 1px;
        box-sizing: border-box;
        /* border-bottom-left-radius: 5px;
        border-top-left-radius: 5px; */
        border-radius: 5px;
        padding-left: 10px;
        box-sizing: border-box;
        font-size: 12px;
    }
    .moderBtn {
        background-color:#116466;
        border: #116466 solid 1px;
        box-sizing: border-box;
        border-bottom-right-radius: 5px;
        border-top-right-radius: 5px;
        width: 25%;
        height: 35px;
        color: white;
        font-size: 10px;
        cursor: pointer;
        margin-left: -3.5px;
    }
    .moderHButton {
        width: 100%;
        max-width: 300px;
        height: 35px;
        border-radius: 5px;
        box-sizing: border-box;
        border:#116466 1px solid ;
        box-sizing: border-box;
        background-color: white;
        color: #116466;
        font-size: 10px;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .moderHButtonVis {
        width: 100%;
        max-width: 300px;
        height: 35px;
        border-radius: 5px;
        box-sizing: border-box;
        border:#116466 1px solid ;
        box-sizing: border-box;
        background-color: #116466;
        color: white;
        font-size: 10px;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

}

/* @media screen and (min-width: 360px) and (max-width: 500px) {
    .moderBtn {
        background-color: #116466;
        border: #116466 solid 0.5px;
        border-bottom-right-radius: 5px;
        border-top-right-radius: 5px;
        width: 20%;
        height: 40px;
        color: white ;
        font-size: 14px;
        font-weight: bold;
        cursor: pointer;
        margin-left: -3.5px;
    }
    .moderHButton {
        width: 100%;
        max-width: 350px;
        height: 40px;
        margin-right: 1%;
        border-radius: 5px;
        border: #116466 1px solid;
        background-color: white;
        color: #116466;
        font-size: 14px;
        cursor: pointer;
        font-weight: bold;
    }
    .moderContInput {
        width: 80%;
        display: flex;
        margin-bottom: 20px;
    }
}
@media screen and (min-width: 200px) and (max-width: 359px) {
    .moderBtn {
        background-color: #116466;
        border: #116466 solid 0.5px;
        border-bottom-right-radius: 5px;
        border-top-right-radius: 5px;
        width: 20%;
        height: 40px;
        color: white ;
        font-size: 12px;
        font-weight: bold;
        cursor: pointer;
        margin-left: -3.5px;
    }
    .moderHButton {
        width: 100%;
        max-width: 350px;
        height: 40px;
        margin-right: 1%;
        border-radius: 5px;
        border: #116466 1px solid;
        background-color: white;
        color: #116466;
        font-size: 12px;
        cursor: pointer;
        font-weight: bold;
    }
    .moderContInput {
        width: 80%;
        display: flex;
        margin-bottom: 20px;
    }
} */

