.contSort {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: sticky;
    top: 0;
    background-color: white;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 7%;
    padding-right: 7%;
    z-index: 10;
    border-bottom: #747474 0.5px solid;
    
}
.sortinpButtonCont {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items:flex-start;
    width: 100%;
}
.sortContInput {
    width: 80%;
    display: flex;
    margin-bottom: 20px;
}
.sortContInput:hover {
    opacity: 0.7;
}
.sortInput {
    width: 80%;
    height: 38px;
    border: #116466 solid 1px;
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    padding-left: 10px;
    box-sizing: border-box;
    /* box-shadow: 0 0 3px 1.5px rgba(0, 0, 0, 0.2); */
    font-size: 12px;
}
.catInput:focus {
    outline: none;
}
.sortInputButton {
    background-color: #116466;
    border: #116466 solid 0.5px;
    /* border-left: none; */
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
    width: 20%;
    height: 38px;
    color: white ;
    font-size: 14px;
    cursor: pointer;
    margin-right: 5px;
    /* box-shadow: 0 0 3px 1.5px rgba(0, 0, 0, 0.2); */
}
.sortInput:focus {
    outline: none;
}
.sortBtnAds {
    width: 100%;
    max-width: 350px;
    height: 38px;
    border-radius: 5px;
    border:#116466 1px solid ;
    background-color: white;
    color: #116466;
    font-size: 14px;
    cursor: pointer;
    /* padding-right: 10px; */
}
.sortAllBtn {
    display: flex;
    flex-direction: row;
    justify-content: left;
    width: 100%;
    position:sticky;
}
.sortBtn {
    display: flex;
    flex-direction: row;
    margin-left: 15px;
    justify-content: space-between;
    padding: 3px;
    align-items: center;
    width: 49%;
    max-width: 150px;
    height: 25px;
    border-radius: 5px;
    border: #747474 0.5px solid;
    background-color: white;
    cursor: pointer;
}
.sortBtn:hover {
    opacity: 0.7;
}
.sortLink {
    width: 20%;
    display: flex;
    text-decoration: none;
    
}
.sortBtnText {
    color: #747474;
    font-size: 10px;
    font-weight: bold;
}
.sortBtnImg {
    width: 15px;
    height: 20px;
}

@media screen and (min-width: 600px) and (max-width: 900px) {
    .sortContInput {
        width: 100%;
        display: flex;
        margin: auto;
        margin-bottom: 20px;
    }
    .sortInput {
        width: 75%;
        height: 38px;
        border: #116466 solid 1px;
        border-bottom-left-radius: 5px;
        border-top-left-radius: 5px;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
        padding-left: 10px;
        box-sizing: border-box;
        font-size: 12px;
    }

    .sortInputButton {
        background-color:#116466;
        border: #116466 solid 2px;
        border-bottom-right-radius: 5px;
        border-top-right-radius: 5px;
        width: 25%;
        height: 38px;
        color: white;
        font-size: 14px;
        cursor: pointer;
        margin-right: 5px;
    }
    .sortBtnAds {
        width: 100%;
        max-width: 3150px;
        height: 38px;
        border-radius: 5px;
        border: none;
        border:#116466 1px solid ;
        background-color: white;
        color: #116466;
        font-size: 14px;
        cursor: pointer;
    }
    .sortBtn {
        display: flex;
        flex-direction: row;
        margin-left: 1%;
        justify-content: space-between;
        padding: 3px;
        padding-left: 2%;
        padding-right: 2%;
        align-items: center;
        width: 45%;
        max-width: 150px;
        height: 25px;
        border-radius: 5px;
        border: #747474 0.5px solid;
        background-color: white;
        cursor: pointer;
    }
    .sortBtnText {
        color: #747474;
        font-size: 9px;
        font-weight: bold;
    }
}
@media screen and (min-width: 200px) and (max-width: 599px) {
    .sortContInput {
        width: 100%;
        display: flex;
        margin: auto;
        margin-bottom: 20px;
    }
    .sortInput {
        width: 75%;
        height: 35px;
        border: #116466 solid 1px;
        box-sizing: border-box;
        border-bottom-left-radius: 5px;
        border-top-left-radius: 5px;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
        padding-left: 10px;
        box-sizing: border-box;
        font-size: 12px;
    }

    .sortInputButton {
        background-color:#116466;
        border: #116466 solid 1px;
        box-sizing: border-box;
        border-bottom-right-radius: 5px;
        border-top-right-radius: 5px;
        width: 25%;
        height: 35px;
        color: white;
        font-size: 10px;
        cursor: pointer;
        margin-right: 5px;
    }
    .sortBtnAds {
        width: 100%;
        max-width: 150px;
        height: 35px;
        border-radius: 5px;
        box-sizing: border-box;
        border:#116466 1px solid ;
        box-sizing: border-box;
        background-color: white;
        color: #116466;
        font-size: 10px;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .sortBtn {
        display: flex;
        flex-direction: row;
        margin-left: 1%;
        justify-content: space-between;
        padding: 3px;
        padding-left: 2%;
        padding-right: 2%;
        align-items: center;
        width: 45%;
        max-width: 150px;
        height: 25px;
        border-radius: 5px;
        border: #747474 0.5px solid;
        background-color: white;
        cursor: pointer;
    }
    .sortBtnText {
        color: #747474;
        font-size: 9px;
        font-weight: bold;
    }
    .sortAllBtn {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        width: 100%;
    }
}

