.contAdForm {
    padding-bottom: 3%;
    padding-top: 1%;
    padding-left: 7%;
    padding-right: 7%;
    display: flex;
    flex-direction: row;
     
}
.imgBack {
    width: 25px;
    height: 25px;
}
.adContText {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 50%;
}
.modalInfo {
    color: #747474;
    line-height: 1.2;
    margin-top: 0px;
    margin-bottom: 5px;
    font-family: Arial, Helvetica, sans-serif;
    white-space: pre-wrap;
}
.timeAd {
    color: #116466;
    line-height: 1.2;
    margin-top: 0px;
    margin-bottom: 20px;
}
.adPrices {
    margin-top: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    width: 100%;
    border-top: #747474 0.5px solid;
    border-bottom: #747474 0.5px solid;
}

  
.eachImgCont {
    text-align: right;
    overflow:auto;
    display: flex;
    box-sizing: border-box;
    width: 50%;
    height: 100%;
    padding: 1%;
    padding-left: 5px;
    margin-left: 1%;
    border-left: #747474 0.5px solid;
    position: relative;
}
.scrollRight {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: white;
    position: absolute;
    top:0px;
    left: 75%;
    border-radius: 5px;
    z-index: 13;
    height: 25px;
    opacity: 0.5;
    
}
.scrollRightImg{
    width: 80px;
    height: 80px;
    z-index: 13;    
}
.eachImg{
    /* position: relative; */
    display: block;
    width: auto;
    height: 500px;
    margin: auto;
    border-radius: 0px;
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.2);
}
.adButton {
    width: 90%;
    max-width: 350px;
    height: 35px;
    border:none;
    border: #116466 1px solid;
    border-radius: 5px;
    background-color: white;
    font-size: 18px;
    color: #116466;
    margin: 20px;
    margin-top: 40px;
    /* box-shadow: 0 0 5px 2px rgba(75, 55, 55, 0.05); */
    cursor: pointer;
}
.adButton:hover {
    opacity: 0.7;
}

@media (min-width: 700px) and (max-width: 1100px) {
    .contAdForm {
        padding: 3%;
        padding-top: 1%;
        display: flex;
        flex-direction:column-reverse;
        align-items: center;
    }
    .adContText {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        overflow: auto;
        width: 100%; 
        padding-left: 4%;
        padding-right: 4%;
        box-sizing: border-box;
    }
    .scrollRight {
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color: white;
        position: absolute;
        bottom: 17%;
        left: 50%;
        border-radius: 5px;
        z-index: 13;
        height: 25px;
        opacity: 0.5;
        
    }
    .scrollRightImg{
        width: 80px;
        height: 80px;
        z-index: 13;    
    }
    .timeAd {
        color: #116466;
        line-height: 1.2;
        margin-top: 5px;
        margin-bottom: 20px;
    }

}
@media (min-width: 451px) and (max-width: 699px) {
    .contAdForm {
        padding-bottom: 3%;
        padding-left: 0px;
        padding-right: 0px;
        padding-top: 1%;
        display: flex;
        flex-direction:column-reverse;
        align-items: center;
    }
    .eachImgCont {
        text-align: center;
        overflow:auto;
        display: flex;
        flex-direction: row;
        box-sizing: border-box;
        width: 100%;
        /* max-width: 600px; */
        height: 100%;
        padding: 0px;
        margin-left: 0px;
        /* box-shadow: -5px 0 3px 3px rgba(0, 0, 0, 0.2); */
        /* border-radius: 15px; */
        border-left: none;
        box-shadow: none;
    }
    .eachImg{
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: auto;
        height: 400px;
        margin: auto;
        border-radius: 0px;
        box-shadow: none;
    }
    .adContText {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        overflow: auto;
        width: 100%; 
        box-sizing: border-box;
        padding-left: 7%;
        padding-right: 7%;
    }
    .scrollRight {
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color: white;
        position: absolute;
        bottom: 250px;
        left: 50%;
        border-radius: 5px;
        z-index: 13;
        height: 25px;
        opacity: 0.5;
        
    }
    .scrollRightImg{
        width: 80px;
        height: 80px;
        z-index: 13;    
    }
    .timeAd {
        color: #116466;
        line-height: 1.2;
        margin-top: 5px;
        margin-bottom: 20px;
    }
}
@media (min-width: 200px) and (max-width: 450px) {
    .contAdForm {
        padding-bottom: 3%;
        padding-left: 0px;
        padding-right: 0px;
        padding-top: 1%;
        display: flex;
        flex-direction:column-reverse;
        align-items: center;
    }
    .eachImgContNew {
        text-align: center;
        overflow:auto;
        display: flex;
        flex-direction: row;
        box-sizing: border-box;
        width: 100%;
        /* max-width: 600px; */
        height: 100%;
        padding: 0px;
        margin-left: 0px;
        /* box-shadow: -5px 0 3px 3px rgba(0, 0, 0, 0.2); */
        /* border-radius: 15px; */
        border-left: none;
        box-shadow: none;
    }
    .eachImgNew{
        position: relative;
        display: block;
        width: 100vw;
        height: 100vw;
        margin: auto;
        border-radius: 0px;
        box-shadow: none;
        object-fit: cover; 
    }
    .adContText {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        overflow: auto;
        width: 100%; 
        box-sizing: border-box;
        padding-left: 7%;
        padding-right: 7%;
    }
    .scrollRight {
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color: white;
        position: absolute;
        bottom: 275px;
        left: 40%;
        border-radius: 5px;
        z-index: 13;
        height: 25px;
        opacity: 0.5;
        
    }
    .scrollRightImg{
        width: 80px;
        height: 80px;
        z-index: 13;    
    }
    .timeAd {
        color: #116466;
        line-height: 1.2;
        margin-top: 5px;
        margin-bottom: 20px;
    }
}