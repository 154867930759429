input, textarea, select, button {
    font-size: 18px;
}
.contSearch {
    padding-top: 20px;
    /* background-color: blanchedalmond; */
}
.headSearch {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-left: 3%;
    padding-right: 3%;
}
.leftSearch {
    width: 25px;
    height: 25px;
}
.inputSearch {
    width: 85%;
    height: 35px;
    background-color: rgb(238, 238, 238);
    border-radius: 25px;
    padding-left: 15px;
    outline: none;
    border:none
}
.underSearch {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    /* background-color: blue; */
}
.under1 {
    text-align: center;
    /* background-color: cyan; */
    width: 50%;
    padding-top: 20px;
    padding-bottom: 15px;
    border-bottom: 1px solid rgb(194, 194, 194);
}
.under1Focus {
    text-align: center;
    /* background-color: cyan; */
    width: 50%;
    padding-top: 20px;
    padding-bottom: 12px;
    border-bottom: 2px solid rgb(1, 68, 145);
}
.textUnder {
    color: rgb(87, 87, 87);
    font-weight: 600;
    margin: 0;
}
.textUnderFocus {
    color:rgb(1, 68, 145);
    margin: 0;
    font-weight: 600;
    
}
.contTable{
    padding-left: 3%;
    padding-right: 3%;
}
.eachTable {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 12px;
}
.clockImg {
    width: 25px;
    height: 25px;
}
.stroke {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    padding-bottom: 12px;
    border-bottom: 1px solid rgb(194, 194, 194)
}
.stroke p {
    margin: 0;
    font-size: 18px;
}
.stroke img {
    height: 25px;
    width: 25px;
}
.eachList {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content:flex-start;
    margin-bottom: 20px;
}
.imgList {
    width: 30px;
    height: 30px;
    margin-right: 10px;
    padding: 7.5px;
    border-radius: 50%;
    background-color: rgb(218, 218, 218);
}
.textList {
    font-size: 18px;
    font-weight: 400;
    margin: 0;

}
