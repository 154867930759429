.contRedFormAd {
    position: fixed;
    top:10%;
    left: 0;
    right: 0;
    width: 800px;
    height: 70vh;
    padding: 3%;
    padding-top: 2%;
    margin: auto;
    border-radius: 5px;
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.05);
    background-color: #b8d1d1;
    overflow: auto;
    z-index: 20
}
.closeImgRed {
    position: absolute;
    width: 25px;
    height: 25px;
    right: 15px;
    top: 15px;
    cursor: pointer;
}
.closeImgRed:hover {
    opacity: 0.7;
}
.pWarning {
    color: red;
    font-size: 14px;
    margin-top: 0px;
    margin-bottom: 15px;
}
.contFormAd {
    width: 800px;
    padding: 3%;
    padding-top: 1%;
    margin: auto;
    border-radius: 5px;
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.05);
    background-color: rgba(17, 100, 102, 0.3)
}
.allInput {
    display: flex;
    flex-direction: row;
    width: 100%;
    /* padding-top: 20px; */
    justify-content: space-between;
}
.threeInput {
    width: 45%;
}

.labelFormAd::-webkit-inner-spin-button,
.no-spinners::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.labelFormAd {
    box-sizing: border-box;
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 5px;
    margin-bottom: 5px;
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.2);
    border: none;
    height: 40px;
    border-radius: 5px;
    -moz-appearance: textfield;
    
}

.labelFormAd:focus {
    outline: none;
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.5);
}
.labelFormAdArea {
    box-sizing: border-box;
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 5px;
    padding-bottom: 5px;
    resize: none;
    margin-top: 5px;
    margin-bottom: 5px;
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.2);
    border: none;
    height: 40px;
    border-radius: 5px;
    font-family: Arial, sans-serif;
}

.labelFormAdArea:focus {
    outline: none;
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.5);
}
.formBtnAd {
    margin-top: 10px;
    width: 100%;
    height: 40px;
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.05);
    border: #116466 1px solid;
    border-radius: 5px;
    background-color: white;
    color: #116466;
    font-size: 18px;
    cursor: pointer;
}
.formBtnAd:hover {
    opacity: 0.7;
}
.formBtnAdSave {
    margin-top: 10px;
    width: 100%;
    height: 40px;
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.05);
    border: none;
    border-radius: 5px;
    background-color:#116466;
    color: white;
    font-size: 18px;
    cursor: pointer;
}
.formBtnAdSave:hover {
    opacity: 0.7;
}
.contMapImgAd {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 90%;
    margin: auto;
    margin-top: 20px;
    /* background-color: violet; */
}
.divImgAd {
    display: flex;
    flex-direction: column;
    width: 32%;
    margin-top: 2%;
}
.imgMapAd {
    width: 100%;
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.05);
}
.btnElement {
    cursor: pointer;
    border: none;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.05);
    height: 25px;
}
.btnElement:hover{
    opacity: 0.7;
}
.redCheckBoxCont {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 400px;
    box-sizing: border-box;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-top: 10px;
    border-radius: 5px;
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.05);
    background-color: white;
    border: #116466 1px solid

}
.labelChackBox {
    width: 100%;
    margin: 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    color: #116466;
    font-size: 18px;
}
.redCheckBox {
    width: 20px;
    height: 20px;
    margin-left: 40px;
}
.redCheckBoxModer {
    width: 20px;
    height: 20px;
    margin-left: 65px;
}
.star {
    width: 10px;
    height: 10px;
    margin-left: 3px;
}
@media screen and (min-width: 290px) and (max-width: 860px) {
    .contRedFormAd {
        position: fixed;
        top:10%;
        left: 0;
        right: 0;
        width: 80%;
        min-width: 300px;
        height: 70vh;
        padding: 15px;
        padding-top: 15px;
        margin: auto;
        border-radius: 5px;
        box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.05);
        background-color: #b8d1d1;
        overflow: auto;
        z-index: 20
    }
    .contFormAd {
        min-width: 300px;
        width: 80%;
        padding: 15px;
        margin: auto;
        border-radius: 5px;
        box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.05);
        background-color: rgba(17, 100, 102, 0.3)
    }
    .allInput {
        display: flex;
        flex-direction: column;
        width: 100%;
        /* padding-top: 20px; */
        justify-content: space-between;
    }
    .threeInput {
        width: 100%;
    }
    .labelFormAd {
        box-sizing: border-box;
        width: 100%;
        padding-left: 10px;
        padding-right: 10px;
        margin-top: 5px;
        margin-bottom: 5px;
        box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.2);
        border: none;
        height: 40px;
        border-radius: 5px;
        
    }
    .labelFormAdArea {
        box-sizing: border-box;
        width: 100%;
        height: auto;
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 5px;
        padding-bottom: 5px;
        resize: none;
        margin-top: 5px;
        margin-bottom: 5px;
        box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.2);
        border: none;
        height: 40px;
        border-radius: 5px;
        
    }
}