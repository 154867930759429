.headProfilePageTop {
    width: 90%;
    height: 200px;
    box-sizing: border-box;
    margin-top: 8px;
    margin-left: 5%;
    margin-right: 5%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    /* background-color: rgb(42, 42, 59); */
}
.headProfilePageBottom {
    position: relative;
    width: 100%;
    height: 50px;
    /* background-color: blue; */
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: space-between;
    /* background-color: grey; */

}
.imageProfilePage {
    position: absolute;
    top:-20px;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 130px;
    height: 130px;
    border-radius: 50%;
    border: 3px solid white;
    background-color: grey;
    margin-bottom: 10px;
    color: white;
    font-size: 70px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.nameProfilePage {
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    margin: 0;
}
.contBtnProfilePage {
    width: 94%;
    box-sizing: border-box;
    margin-top: 15px;
    margin-left: 3%;
    margin-right: 3%;
    padding-bottom: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-bottom: 0.5px solid grey;
}
.btnAddProfilePage {
    width: 86%;
    height: 38px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    background-color: #1877F2;
    cursor: pointer;
}
.btnAddProfilePage p {
    color: white;
    font-weight: 500;
    font-size: 16px;
}
.btnAddProfilePage:hover {
    opacity: 0.8;
}
.btnTelega {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 38px;
    width: 12%;
    border-radius: 5px;
    background-color: rgb(223, 223, 223);
}
.imgTelega {
    width: 23px;
    height: 23px;
    margin-right: 2px;
}
