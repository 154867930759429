.modalHidden {
    background-color: rgba(97, 97, 97, 0.9);
    width: 100%;
    height: 100%;
    position: fixed;
    top:0;
    left:0;
    z-index: 21;
    display: flex;
    justify-content: center;
    align-items: center;
}
.modalBlock {
    width: 90%;
    max-width: 300px;
    position: fixed;
    box-sizing: border-box;
    padding: 20px;
    border-radius: 15px;
    background-color: white;
    z-index: 23;
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.3);
    text-align: center;
}
.alertDesc {
    margin: 0;
    margin-bottom: 20px;
    font-size: 15px;
    color: #116466;
}
.contButtonsAlert{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.btnDelete {
    width: 45%;
    height: 30px;
    color:#116466;
    border:1px solid #116466;
    border-radius: 5px;
    background-color: white;
    box-sizing: border-box;
    cursor: pointer;
}
.btnDelete:hover {
    opacity: 0.7;
}
.btnCancle {
    width: 45%;
    height: 30px;
    color: white;
    background-color: #116466;
    border:1px solid #116466;
    border-radius: 5px;
    cursor: pointer;
}
.btnCancle:hover {
    opacity: 0.7;
}