.contHeadProfile {
    position: relative;
    text-align: center;
    /* background-color: cyan; */
    border-bottom: 0.5px solid rgb(194, 194, 194)
}
.profileBackImg {
    position: absolute;
    left: 3%;
    width: 25px;
    height: 25px;
}
.contName {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding-top: 5px;
    padding-left: 3%;
    padding-right: 3%;
}
.imgProfileTg {
    width: 42.5px;
    height: 42.5px;
    margin-right: 15px;
    border-radius: 50%;
}
.imgProfile {
    width: 30px;
    height: 30px;
    margin-right: 15px;
    padding: 7.5px;
    border-radius: 50%;
    background-color: rgb(218, 218, 218);
}
.textName {
    font-size: 18px;
    font-weight: 600;
}

/* Блок из 4 элементов */
.contFavoriteProfile {
    /* background-color: cyan; */
    padding-left: 3%;
    padding-right: 3%;
    padding-top: 2%;
    /* padding-bottom: 2%; */
    /* border-bottom: 1px solid rgb(236, 236, 236); */

}
.twoBlocksProfile {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2%;
}
.leftElement {
    width: 49%;
    box-sizing: border-box;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid rgb(236, 236, 236);
}
.imgElementProfile {
    width: 25px;
    height: 25px;
}
.textElementProfile {
    margin: 0;
    font-weight: 600;
    font-size: 15px;
}

/* Блок моих объявлений */
.allMyAds {
    padding-left: 3%;
    padding-right: 3%;
    padding-bottom: 10px;
    
}
.eachmyAd {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 15px;
}
.imgMyAds {
    width: 60px;
    height: 60px;
    object-fit: cover; 
    border-radius: 10px;
    margin-right: 10px;
}
.contTextMyAds {
    height: 60px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.text1MyAd {
    margin: 0;
    color: rgb(119, 119, 119);
}
.text2MyAd {
    margin: 0;
    font-size: 12px;
    /* color: rgb(119, 119, 119); */
}
.text3MyAd {
    margin: 0;
    font-size: 14px;
    color: rgb(119, 119, 119);
}
.imgMyAdsRight {
    position: absolute;
    right: 0px;
    width: 15px;
    height: 15px;
    right: 5px;
}
.btnSell {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 79%;
    height: 30px;
    background-color: #cce3fd;
    margin-left: 20%;
    color: #1877F2;
    font-size: 13px;
    font-weight: bold;
    margin-top: 5px;
    margin-bottom: 7px;
    border-radius: 5px;

}
.btnPromote {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 79%;
    height: 30px;
    background-color: rgb(218, 218, 218);
    margin-left: 20%;
    color: rgb(53, 53, 53);
    font-size: 13px;
    font-weight: bold;
    margin-top: 5px;
    margin-bottom: 15px;
    border-radius: 5px;
}

/* Кнопка создать объявление */
.btnAdd {
    width: 100%;
    height: 38px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    background-color: #1877F2;
    margin-top: 20px;
    cursor: pointer;
}
.btnAdd p {
    color: white;
    font-weight: 500;
    font-size: 16px;
}
.btnAdd:hover {
    opacity: 0.8;
}
