.contNotFound {
    width:90%;
    max-width: 400px;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
    text-align: center;
}
.contNotFound h3 {
    color: #116466;
    font-size: 98px;
    font-weight: bold;
    margin: 0;

}
.contNotFound h4 {
    color: #116466;
    font-size: 32px;
    font-weight: bold;
    margin: 0;
    margin-bottom: 20px;
}
.contNotFound p {
    font-size: 18px;
    margin: 0px;
}