.rulesCont {
    max-width: 1000px;
    box-sizing: border-box;
    width: 90%;
    margin: auto;
    margin-bottom: 0px;
    box-shadow: 0 0 3px 1.5px rgba(0, 0, 0, 0.2);
    padding: 5%;
    padding-top: 40px;
    padding-bottom: 30px;
    border-radius: 5px;
    margin-top: 20px;
    margin-bottom: 20px;
}
.rulesCont li {
    color: #747474;
    font-size: 16px;
}
.rulesCont ul {
    color: #747474;
    font-size: 16px;
}
.rulesCont p {
    color: #272727;
    font-size: 16px;
}
.rulesH3 {
    font-size: 26px;
    color: #116466;
    margin-top: 0;
}
.rulesH4 {
    color: #272727;
    font-weight: bold;
}
.rulesTextarea {
    width: 100%;
    height: 200px;
    border:#116466 solid 1px;
    border-radius: 5px;
    margin-bottom: 20px;
    box-sizing: border-box;
    padding: 10px;
}
.rulesTextarea:focus {
    outline: none;
    box-shadow: 0 0 3px 1.5px rgba(0, 0, 0, 0.2);

}
.rulesLabel {
    color: #272727
}
.rulesInput {
    width: 100%;
    height: 40px;
    border:#116466 solid 1px;
    border-radius: 5px;
    margin-bottom: 20px;
    box-sizing: border-box;
    padding-left: 10px;
    padding-right: 10px;
}
.rulesInput:focus {
    outline: none;
    box-shadow: 0 0 3px 1.5px rgba(0, 0, 0, 0.2);
}
.rulesBtn {
    width: 100%;
    height: 40px;
    color: white;
    font-size: 18px;
    border: none;
    background-color: #116466;
    border-radius: 5px;
    box-shadow: 0 0 3px 1.5px rgba(0, 0, 0, 0.2);
    cursor: pointer;
}
.rulesBtn:hover {
    opacity: 0.7;
}
.imgBackRules {
    margin-top: 2px;
    width: 35px;
    height: 35px;
    margin-right: 15px;
    opacity: 0.7;
    cursor: pointer;
}
.btnTerms {
    width: 100%;
    max-width: 500px;
    height: 35px;
    font-size: 16px;
    color:#116466;
    border: #116466 1px solid;
    border-radius: 5px;
    background-color: white;
    cursor: pointer;
}
.btnTerms:hover{
    opacity: 0.7;
}
@media screen and (min-width: 200px) and (max-width: 750px) {
    .rulesCont {
        max-width: 1000px;
        box-sizing: border-box;
        width: 90%;
        margin: auto;
        margin-bottom: 0px;
        /* box-shadow: 0 0 3px 1.5px rgba(0, 0, 0, 0.2); */
        padding: 5%;
        padding-top: 30px;
        padding-bottom: 30px;
        border-radius: 5px;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .rulesCont li {
        color: #747474;
        font-size: 16px;
    }
    .rulesCont p {
        color: #272727;
        font-size: 16px;
    }
    .rulesH3 {
        font-size: 26px;
        color: #116466;
        margin-top: 0;
    }
    .rulesH4 {
        color: #272727;
        font-size: 24px;
    }
}
