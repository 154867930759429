
.imgBackForm {
    width: 25px;
    height: 25px;
    background-color: rgba(255, 251, 251, 0.6);
    border-radius: 50%;

    padding: 5px;
}
.eachImgContNew {
    display: flex;
    flex-direction: row;
    overflow-x: auto; /* Горизонтальный скроллинг */
    width: 100vw; /* Полная ширина экрана */
    height: 100vw; /* Квадрат, равный ширине экрана */
    scroll-snap-type: x mandatory; /* Автоматическая остановка прокрутки */
    box-sizing: border-box;
    padding: 0;
    margin: 0;
  }
  
  .eachImgNew {
    flex: none; /* Убедитесь, что изображение не растягивается */
    width: 100vw; /* Полная ширина экрана */
    height: 100vw; /* Полная высота экрана, чтобы сохранить квадратную форму */
    object-fit: cover; /* Изображение сохраняет свои пропорции и заполняет блок */
    scroll-snap-align: start; /* Начало прокрутки для каждого изображения */
  }
  .textUderPhoto1 {
      margin: 0;
      margin-left: 3%;
      margin-right: 3%;
      margin-top: 3%;
      font-size: 22px;
      font-weight: bold;
  }
  .textUderPhoto2 {
    margin: 0;
    margin-left: 3%;
    margin-right: 3%;
    /* margin-top: 3%; */
    font-size: 18px;
}
.textUderPhoto3 {
    margin: 0;
    margin-left: 3%;
    margin-right: 3%;
    margin-top: 2%;
    font-size: 14px;
    color: grey;
}
.blockMes {
    margin-left: 4%;
    margin-right: 4%;
    margin-top: 3%;
    width: 92%;
    border: 0.5px solid rgb(194, 194, 194);
    border-radius: 8px;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2);
}
.text1Block {
    display: flex;
    flex-direction: row;
    /* align-items: center; */
    /* justify-content: center; */
    margin: 0;
    margin-left: 3%;
    margin-top: 3%;
    font-size: 15px;
    font-weight: 500;
}
.text1Block img {
    width: 20px;
    height: 20px;
    margin-right: 5px;
}
.contInputBlock {
    width: 100%;
    box-sizing: border-box;
    padding: 3%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    
}
.inputBlock {
    width: 65%;
    height: 25px;
    background-color: rgb(238, 238, 238);
    border-radius: 25px;
    padding-left: 15px;
    outline: none;
    border:none;
    /* font-size: 14px; */
    font-size: 14px;
}

.btnInputBlock {
    width: 27%;
    height: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    background-color: #1877F2;
    cursor: pointer;
    padding-bottom: 1px;
    box-sizing: border-box;
}
.btnInputBlock p {
    color: white;
    font-weight: 500;
    font-size: 13px;
    /* margin-bottom: 1px; */
}
.btnInputBlock:hover {
    opacity: 0.8;
}
/* Блок оповещений */
.blockAlarm {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-left: 3%;
    margin-right: 3%;
    margin-top: 5%;
    padding-bottom: 3%;
    border-bottom: 1px solid rgb(194, 194, 194)
}
.eachAlarm {
    width: 25%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.imgContAlarm {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgb(207, 207, 207);
    padding: 10px;
    border-radius: 50%;
}
.imgContAlarm p {
    position: absolute;
    background-color: white;
    top:-30px;
    border: 0.8px solid grey;
    border-radius: 5px;
    padding: 3px;
    color: grey;
}
.eachAlarm img {
    width: 25px;
    height: 25px;
}
.eachAlarm p{
    margin: 0;
    margin-top: 5px;
    font-size: 12px;
    font-weight: 600;
}
.blockDesc {
    margin-left: 3%;
    margin-right: 3%;
    padding-bottom: 20px;
    border-bottom: 1px solid rgb(194, 194, 194)
}
.contImgProfile {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.imgProfile2 {
    width: 30px;
    height: 30px;
    padding: 10px;
    border-radius: 50%;
    background-color: rgb(207, 207, 207);
    margin-right: 10px;
}
.imgProfileBtn {
    position: absolute;
    right: 0px;
    width: 30%;
    height: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    background-color: rgb(207, 207, 207);
    cursor: pointer;
    padding-bottom: 1px;
    box-sizing: border-box;
}
.imgProfileBtn p {
    font-weight: 600;
    font-size: 15px;
}
.imgProfileBtn:hover {
    opacity: 0.8;
}
/* Table.css */
.table-container {
    width: 100%;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
  }
  
  td {
    padding: 7px;
    border: none; /* Границы невидимые */
  }
  
  .left-column {
    width: 40%;
    color: grey;
  }
  
  .right-column {
    width: 60%;
  }
  