.cont {
  min-height: 100vh;
  min-width: 300px;
  /* box-sizing: border-box; */

}
.scrollUp {
  position: fixed;
  bottom: 21px;
  right: 20px;
  width: 55px;
  height: 55px;
  opacity: 0.8;
  z-index: 100;
}
.scrollUp:hover{
  opacity: 0.7;
}


