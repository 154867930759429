.contAdModal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(0, 0, 0, 0.6);
    z-index: 2;
    
}
.windowAdModal {
    position: fixed;
    width: 100%;
    padding-left: 3%;
    padding-right: 3%;
    box-sizing: border-box;
    /* height: 30vh; */
    bottom: 0;
    left: 0;
    background-color: white;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    z-index: 3;
    animation-duration: 1s; /* Продолжительность анимации */
    animation-timing-function: ease; /* Функция времени (плавное изменение) */
}
.headWindowModal {
    font-size: 18px;
    font-weight: 800;
}

@keyframes slideUp {
    0% {
        bottom: -100%; /* Начальная позиция за пределами экрана */
    }
    100% {
        bottom: 0; /* Конечная позиция внизу экрана */
    }
}
@keyframes slideDown {
    0% {
        bottom: 0; /* Начальная позиция за пределами экрана */
    }
    100% {
        bottom: -100%; /* Конечная позиция внизу экрана */
    }
}